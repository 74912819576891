import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Grid, Icon, Image, Input } from "semantic-ui-react";
import { QueueController } from "react-lib/apps/QUE/Time";
import "react-lib/apps/QUE/Time.css";

import { useHistory, RouteComponentProps } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import { makeStyles } from "@mui/styles";
import SelectDate from "react-lib/apps/QUE/SelectDate";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Slide,
  Typography,
} from "@mui/material";
//image
import personImg from "react-lib/apps/common/person.png";
import TFHead from "react-lib/apps/IsHealth/Transform/TFHead";
import SwipeableViews from "react-swipeable-views";
import moment from "moment";
import {
  adToBe,
  adToBeWithSetFormat,
  dateToStringWithoutTimeBE,
} from "react-lib/utils/dateUtils";
import * as Util from "react-lib/utils";
import "react-lib/css/PatientSelectAppointmentIH.scss";
import MobHeader from "react-lib/apps/IsHealth/IHMobile/MobHeader";
import { useIntl } from "react-intl";

interface RouterProps {
  appointId: string;
  appointStatus: string;
  controller: any;
}

interface IshealthPatientEditAppointmentProps
  extends RouteComponentProps<RouterProps, any, { channelId?: string }> {
  appointmentData?: any;
  setProp?: any;
  apiToken?: string;
  controller?: QueueController;
  divisionId?: number | string;
  intl?: IntlShape;
  onEvent?: any;
  createAppointment?: any;
  divisionList?: any;
  doctorList?: any;
  errorMessage?: any;
  successMessage?: any;
  timeDivision?: any[];
}

const useStyles = makeStyles((theme) => ({
  panes: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    padding: "5px 0px",
  },
  cadeBox: {
    borderRadius: "10px",
    margin: "10px",
    boxSizing: "border-box",
    border: "0.5px solid rgba(227,239,255,0.5)",
    boxShadow: "3px 3px 0px rgba(227,239,255,0.5)",
    backgroundColor: "white",
  },
}));

const COLOR = {
  primary: "var(--primary-theme-color)",
  primary_font: "var(--primary-font-color)",
  grey: `#9e9e9e`,
};

const IshealthPatientEditAppointment = (
  props: IshealthPatientEditAppointmentProps
) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const toTime = moment().format("HH:mm");
  const toDate = moment().format("YYYY-MM-DD");
  const [hospital, setHospital] = useState(false);
  const [telemed, setTelemed] = useState(false);
  const [addPerson, setAddPerson] = useState(false);
  const [expanded, setExpanded] = useState<string | false>("");
  const [selectTime, setSelectTime] = useState<any>("selectTime");
  const [checked, setChecked] = useState(false);
  const [dateDivisionSchedule, setDateDivisionSchedule] = useState<any>();
  const [timeDivision, setTimeDivision] = useState<any>();
  const [doctorName, setDoctorName] = useState<any>();
  const [timeValue, setTimeValue] = useState<any>();
  const [postPoneDetail, setPostPoneDetail] = useState<boolean>(false);

  useEffect(() => {
    props.onEvent({
      message: "HandleGetAppointment",
      params: {
        id: props.match?.params?.appointId,
      },
    });
    // handleDivisionScheduleTimeList();
    // handleSetTextTime();

    return () => {
      props.setProp(`appointmentData`, {});
    };
  }, []);

  useEffect(() => {
    setSelectTime("");
  }, [props.appointmentData?.selectDate]);

  useEffect(() => {
    setTimeDivision(props.timeDivision);
    if (
      props.timeDivision &&
      props.timeDivision.length > 0 &&
      props.appointmentData
    ) {
      props.timeDivision?.forEach((item: any, index: number) => {
        let matchItem = item.doctorDSB.find(
          (dsb: any) =>
            dsb.division_service_block ===
            props.appointmentData.division_service_block
        );
        if (matchItem) {
          setExpanded(index.toString());
          let findTimeIndex = matchItem?.timeValue?.indexOf(
            props.appointmentData?.time
          );
          if (findTimeIndex !== -1) {
            setSelectTime(findTimeIndex);
          }
        }
      });
    }
  }, [props.timeDivision]);

  useEffect(() => {
    if (props?.successMessage) {
      history.push(`/appointment`);
    } else if (props?.errorMessage) {
      return;
    }

    props.setProp("successMessage", null);
    props.setProp("errorMessage", null);
  }, [props.errorMessage, props.successMessage]);

  useEffect(() => {
    // handleDoctorDivisionServiceBlockSchedule();

    if (props.appointmentData?.data?.is_telemed) {
      setTelemed(true);
      setHospital(false);
    } else {
      setTelemed(false);
      setHospital(true);
    }
  }, [props?.appointmentData?.data]);

  useEffect(() => {
    handleDoctorDivisionServiceBlockSchedule();
  }, [telemed]);

  useEffect(() => {
    handleSetData();
    // handleDivisionScheduleTimeList();
    // if (props?.appointmentData?.selectDate === undefined ||
    //   props?.appointmentData?.selectDate !== moment(props.appointmentData?.data?.estimated_at_iso).format("YYYY-MM-DD")) {
    //   props.setProp(
    //     "appointmentData.selectDate",
    //     moment(props.appointmentData?.data?.estimated_at_iso).format("YYYY-MM-DD")
    //   );
    // }
  }, [props.createAppointment?.doctorDivisionServiceBlockSchedule]);

  useEffect(() => {
    if (props?.appointmentData?.selectDate) {
      props.onEvent({
        message: "HandleDivisionScheduleTimeList",
        params: {
          divisionID: props?.appointmentData?.data?.division,
          selectDate: props?.appointmentData?.selectDate
            ? dateToStringWithoutTimeBE(props?.appointmentData?.selectDate)
            : "",
        },
      });

      props.onEvent({
        message: "HandleGetTimeSlotByDate",
        params: {
          selectDate: props?.appointmentData?.selectDate || "",
          selectTime: props?.appointmentData?.time || "",
        },
      });
    }
    // handleSetTextTime();

    // props.setProp("appointmentData", {
    //   ...props.appointmentData,
    //   personPhone: props?.createAppointment?.userProfile?.ecp_phone_no,
    //   personLastName: props?.createAppointment?.userProfile?.ecp_last_name,
    //   personName: props?.createAppointment?.userProfile?.ecp_first_name
    // }
    // )
  }, [props?.appointmentData?.selectDate]);

  const handleChangeDistance =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleCheckType = (checked: any, type: any) => {
    if (type === "hospital") {
      setHospital(checked);
      if (telemed) {
        setTelemed(false);
      }
    } else if (type === "telemed") {
      setTelemed(checked);
      if (hospital) {
        setHospital(false);
      }
    }
  };

  const handleCreateAppointmentIH = () => {
    props.onEvent({
      message: "HandleUpdateAppointment",
      params: {
        date: moment(
          `${props.appointmentData?.selectDate} ${props.appointmentData?.time}`,
          "YYYY-MM-DD HH:mm"
        ).toISOString(),
        is_telemed: telemed,
      },
    });
  };

  const goBackDoctor = () => {
    setChecked(!checked);
  };

  const handleDoctorDivisionServiceBlockSchedule = () => {
    if (
      props?.appointmentData?.data?.display_info?.provider_object_id !==
      undefined
    ) {
      let selectedDate = moment();
      if (props.appointmentData?.selectDate) {
        selectedDate = moment(props.appointmentData?.selectDate, "YYYY-MM-DD");
      }
      props.onEvent({
        message: "HandleDoctorDivisionServiceBlockSchedule",
        params: {
          doctorID:
            props?.appointmentData?.data?.display_info?.provider_object_id,
          month: selectedDate.format("M"),
          year: selectedDate.format("YYYY"),
          is_telemed: telemed,
        },
      });
    }
  };

  const handleSetData = () => {
    var dataSchedule: any[] = [];
    props.createAppointment?.doctorDivisionServiceBlockSchedule?.forEach(
      (item: any) => {
        if (
          moment(item?.start_datetime_iso).format("YYYY-MM-DD") === toDate &&
          moment(item?.end_datetime_iso).format("HH:mm") <= toTime
        ) {
          return;
        } else if (
          moment(item?.start_datetime_iso).format("YYYY-MM-DD") >= toDate
        ) {
          dataSchedule.push(
            moment(item?.start_datetime_iso).format("YYYY-MM-DD")
          );
        }
      }
    );

    setDateDivisionSchedule(dataSchedule);
  };

  // const handleDivisionScheduleTimeList = async () => {
  //   props.createAppointment?.doctorDivisionServiceBlockSchedule?.forEach(
  //     (item: any) => {
  //       if (props?.appointmentData?.selectDate === undefined) {
  //         props.setProp(
  //           "appointmentData.selectDate",
  //           moment(props.appointmentData?.data?.estimated_at_iso).format(
  //             "YYYY-MM-DD"
  //           )
  //         );
  //       }
  //       if (
  //         moment(item?.start_datetime_iso, "YYYY-MM-DD").format(
  //           "YYYY-MM-DD"
  //         ) === props?.appointmentData?.selectDate
  //       ) {
  //         props.setProp("appointmentData.doctor", item);

  //         let timeData =
  //           ((parseFloat(moment(item?.end_datetime_iso).format("H")) -
  //             parseFloat(moment(item?.start_datetime_iso).format("H"))) /
  //             item?.slot_length) *
  //           60;
  //         let timeValue: any[] = [];

  //         for (let i = 0; i < timeData; i++) {
  //           if (i === 0) {
  //             timeValue.push(moment(item?.start_datetime_iso).format("HH:mm"));
  //           } else {
  //             timeValue.push(
  //               moment(item?.start_datetime_iso)
  //                 .add(item?.slot_length * i, "minutes")
  //                 .format("HH:mm")
  //             );

  //             if (
  //               moment(item?.start_datetime_iso)
  //                 .add(item?.slot_length * i, "minutes")
  //                 .format("HH:mm") === props.appointmentData?.time
  //             ) {
  //               setSelectTime(i);
  //             }
  //           }
  //         }

  //         setTimeValue(timeValue);
  //       }
  //     }
  //   );
  // };

  // const handleSetTextTime = () => {
  //   let dataTime: any[] = [];
  //   let dataTimeAndDoctorDSB: any[] = [];
  //   props.createAppointment?.doctorDivisionServiceBlockSchedule?.forEach(
  //     (item: any) => {
  //       if (
  //         moment(item?.start_datetime_iso, "YYYY-MM-DD").format(
  //           "YYYY-MM-DD"
  //         ) === props?.appointmentData?.selectDate
  //       ) {
  //         //set text for date time
  //         let textTime = `${moment(item?.start_datetime_iso).format(
  //           "HH:mm"
  //         )} - ${moment(item?.end_datetime_iso).format("HH:mm")}`;
  //         if (dataTime.length === 0) {
  //           dataTime.push(textTime);
  //           dataTimeAndDoctorDSB.push({ periodTime: textTime, doctorDSB: [] })
  //         } else if (dataTime.length !== 0 && !dataTime.includes(textTime)) {
  //           dataTime.push(textTime);
  //           dataTimeAndDoctorDSB.push({ periodTime: textTime, doctorDSB: [] })
  //         }

  //         let indOfDataTime = dataTime.indexOf(textTime)

  //         // set selectable time
  //         let timeData =
  //           ((parseFloat(moment(item?.end_datetime_iso).format("H")) -
  //             parseFloat(moment(item?.start_datetime_iso).format("H"))) /
  //             item?.slot_length) *
  //           60;
  //         let timeValue: any[] = [];
  //         for (let i = 0; i < timeData; i++) {
  //           // TO DO verified patient app
  //           timeValue.push(
  //             moment(item?.start_datetime_iso)
  //               .add(item?.slot_length * i, "minutes")
  //               .format("HH:mm")
  //           );
  //         }

  //         dataTimeAndDoctorDSB[indOfDataTime]["doctorDSB"].push({ ...item, timeValue: timeValue })
  //       }
  //     }
  //   );
  //   setTimeDivision(dataTimeAndDoctorDSB);
  // };

  const handleSelectTime = (index: any, time: any, item: any) => {
    setSelectTime(index);
    props.setProp("appointmentData.doctor", item);
    props.setProp("appointmentData.time", time);
  };

  const handleChangeValue = (key: string) => (_event: any, data: any) => {
    if (data.type === "checkbox" || data.type === "radio") {
      props.setProp(`appointmentData.${key}`, data.checked);
    } else {
      let value = data.value;

      if (key === "personPhone") {
        value = value.slice(0, 10);
      }

      props.setProp(`appointmentData.${key}`, value);
    }
  };

  const handleChangeMonthYear = (month: string, year: string) => {
    props.onEvent({
      message: "HandleDoctorDivisionServiceBlockSchedule",
      params: {
        divisionID:
          props.match.params.appointStatus === "division"
            ? props.match?.params?.appointId
            : "",
        doctorID:
          props.match.params.appointStatus === "doctor"
            ? props.match?.params?.appointId
            : "",
        month: month,
        year: year,
        is_telemed: telemed,
      },
    });
  };

  const handleCheckedDataDetail = () => {
    setPostPoneDetail(true);
  };

  const handleSetDate = (date: any) => {
    return moment(Util.formatDate(moment(date)), "DD/MM/YYYY").format(
      "DD MMMM YYYY"
    );
  };

  const handleSetDefaultEmergencyContact = () => {
    setAddPerson(true);
    props.setProp(
      `appointmentData.personName`,
      props.appointmentData?.data?.emergency_contact_first_name
    );
    props.setProp(
      `appointmentData.personLastName`,
      props.appointmentData?.data?.emergency_contact_last_name
    );
    props.setProp(
      `appointmentData.personPhone`,
      props.appointmentData?.data?.emergency_contact_telephone_number
    );
  };

  return (
    <div>
      <div className="content-header">
        <MobHeader
          leftIconClick={() => {
            if (postPoneDetail) {
              setChecked(false);
              setPostPoneDetail(false);
            } else {
              history.goBack();
            }
          }}
          title={intl.formatMessage({ id: "นัดหมาย" })}
          history={props.history}
        />

        <div className="content-detail">
          {!postPoneDetail ? (
            <div
              style={{
                overflow: "auto",
                borderTopLeftRadius: "50px",
                borderTopRightRadius: "50px",
                background: "#FFFFFF",
              }}
            >
              <div style={{ margin: "20px" }}>
                <div
                  className="content-header-laban"
                  style={{ marginTop: "25%" }}
                >
                  {"กรุณาระบุเหตุผลที่ท่านเลื่อนนัดหมาย"}
                </div>
                <div className="content-input-detail">
                  <Input
                    fluid={true}
                    onChange={(_event: any, data: any) => {
                      props.setProp(
                        `appointmentData.postponeDetail`,
                        data.value
                      );
                    }}
                    value={props?.appointmentData?.postponeDetail || ""}
                    placeholder={intl.formatMessage({
                      id: "กรุณาระบุเหตุผลที่ท่านเลื่อนนัดหมาย",
                    })}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="content-button "
                    onClick={handleCheckedDataDetail}
                  >
                    {"ดำเนินการต่อ"}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                position: "absolute",
                zIndex: "0",
              }}
            >
              <div style={{ overflow: "auto" }}>
                {/* Doctor */}
                <CardDoctor
                  classes={classes}
                  data={props?.appointmentData?.data}
                  languageUX={props.languageUX}
                />
                <Slide
                  direction="right"
                  in={!checked}
                  mountOnEnter
                  unmountOnExit
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        margin: "10px 0px",
                      }}
                    >
                      <div
                        style={{
                          padding: "0 20px",
                          display: "flex",
                          width: "95%",
                        }}
                      >
                        <div
                          className="tab-selected-telemed"
                          style={{
                            ...(hospital && {
                              borderBottom: "2px solid #1AB3CB",
                              color: "#1AB3CB",
                            }),
                          }}
                          onClick={() => handleCheckType(true, "hospital")}
                        >
                          โรงพยาบาล
                        </div>
                        <div
                          className="tab-selected-telemed"
                          style={{
                            ...(telemed && {
                              borderBottom: "2px solid #1AB3CB",
                              color: "#1AB3CB",
                            }),
                          }}
                          onClick={() => handleCheckType(true, "telemed")}
                        >
                          ออนไลน์
                        </div>
                      </div>
                    </div>

                    {/* calendar */}
                    <SelectDate
                      dateDivisionSchedule={dateDivisionSchedule}
                      setProp={props.setProp}
                      date={props.appointmentData?.data?.estimated_at_iso}
                      onClickPrev={handleChangeMonthYear}
                      onClickNext={handleChangeMonthYear}
                      checkSelectDate={true}
                    />

                    {/* Time */}
                    <div style={{ margin: "15px 5%" }}>
                      <div
                        className={"content-header-laban"}
                        style={{
                          fontWeight: "bold",

                          borderLeft: "2px solid #1AB3CB",
                          paddingLeft: "10px",
                        }}
                      >
                        เวลานัดหมาย
                      </div>

                      <div
                        className={"appointment-selected-time-slot"}
                        style={{
                          margin: "10px 0px",
                          ...((timeDivision === undefined ||
                            timeDivision.length < 1) && { display: "none" }),
                        }}
                      >
                        {timeDivision?.map((item: any, number: any) => {
                          return (
                            <Accordion
                              expanded={expanded === number}
                              onChange={handleChangeDistance(number)}
                            >
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                                expandIcon={
                                  <Icon className="angle down"></Icon>
                                }
                              >
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color:
                                      expanded === number
                                        ? COLOR.primary_font
                                        : COLOR.grey,
                                  }}
                                >
                                  {item?.periodTime}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails style={{ padding: "0px" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Grid
                                    columns={3}
                                    style={{
                                      margin: "0px 10px 10px",
                                      height: "100px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {item.doctorDSB?.map(
                                      (doctor: any, doctorOrder: number) => {
                                        return doctor.timeValue
                                          ?.filter((time: any) => {
                                            if (
                                              props.appointmentData
                                                ?.selectDate === toDate
                                            ) {
                                              return toTime < time;
                                            } else {
                                              return time;
                                            }
                                          })
                                          ?.map((time: any, index: any) => {
                                            return (
                                              <Grid.Column
                                                columns={3}
                                                style={{ padding: "0px" }}
                                              >
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      selectTime === index
                                                        ? "rgba(93,188,210,0.25)"
                                                        : "rgba(227,239,255,0.5)",
                                                    margin: "10px",
                                                    justifyContent: "center",
                                                    display: "flex",
                                                    padding: "5px",
                                                    borderRadius: "10px",
                                                    fontWeight: "bold",
                                                    color:
                                                      selectTime === index
                                                        ? COLOR.primary
                                                        : COLOR.grey,
                                                    border:
                                                      selectTime === index
                                                        ? `1px solid ${COLOR.primary}`
                                                        : "",
                                                  }}
                                                  onClick={() =>
                                                    handleSelectTime(
                                                      index,
                                                      time,
                                                      doctor
                                                    )
                                                  }
                                                >
                                                  {time}
                                                </div>
                                              </Grid.Column>
                                            );
                                          });
                                      }
                                    )}
                                  </Grid>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })}
                      </div>
                    </div>
                    {/* Button */}
                    <div style={{ margin: "30px 0px" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div style={{ width: "80%", fontWeight: "bold" }}>
                          <div
                            className={`content-button ${
                              (!props.appointmentData?.time ||
                                !props.appointmentData?.selectDate) &&
                              "disable"
                            }`}
                            onClick={() => {
                              if (
                                props.appointmentData?.time &&
                                props.appointmentData?.selectDate
                              ) {
                                setChecked(!checked);
                              }
                            }}
                          >
                            {"ดำเนินการต่อ"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slide>
                <div>
                  <Slide
                    direction="left"
                    in={checked}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <CardSummary
                        classes={classes}
                        checked={checked}
                        setChecked={setChecked}
                        addPerson={addPerson}
                        setAddPerson={setAddPerson}
                        handleCreateAppointmentIH={handleCreateAppointmentIH}
                        goBack={goBackDoctor}
                        data={props.appointmentData}
                        handleChangeValue={handleChangeValue}
                        hospital={hospital}
                        telemed={telemed}
                        handleSetDate={handleSetDate}
                        handleSetDefaultEmergencyContact={
                          handleSetDefaultEmergencyContact
                        }
                        languageUX={props.languageUX}
                      />
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

IshealthPatientEditAppointment.defaultProps = {};

const CardDoctor = (props: any) => {
  return (
    <div className="card-selected-doctor">
      <div
        style={{
          display: "flex",
          padding: "10px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "15%", margin: "5px" }}>
          <Image
            src={
              props.data?.display_info?.image
                ? props.data.display_info.image
                : personImg
            }
          />
        </div>
        <div
          style={{
            width: "75%",
            margin: "10px",
          }}
        >
          <div
            style={{
              color: "#1ABDD4",
              fontWeight: "bold",
            }}
          >
            {props.data?.display_info?.provider_name}
          </div>
          <div style={{ color: COLOR.grey, padding: "5px 0px" }}>
            {props.data?.display_info?.division_name}
          </div>
          <div style={{ color: COLOR.grey }}>
            {props.data?.display_info?.hospital_name}
          </div>
        </div>
      </div>
    </div>
  );
};

const CardSummary = (props: any) => {
  const intl = useIntl();
  useEffect(() => {
    if (
      props.data?.data?.emergency_contact_first_name &&
      props.data?.data?.emergency_contact_last_name &&
      props.data?.data?.emergency_contact_telephone_number
    ) {
      props.handleSetDefaultEmergencyContact?.();
    }
  }, []);

  return (
    <div>
      <div className={""}>
        {/* Confirm */}
        <div style={{ margin: "35px 5%" }}>
          <div
            className={"content-header-laban"}
            style={{ fontWeight: "bold" }}
          >
            วันและเวลานัดหมาย
          </div>

          <div
            className="appointment-card-summary"
            onClick={() => props.goBack()}
          >
            <div>
              <div className="card-appointment-detail">
                <Icon
                  circular
                  name="calendar alternate outline"
                  size="small"
                  style={{
                    background: "#E3F3F9",
                  }}
                />
                <div style={{ marginLeft: "10px" }}>
                  {props.handleSetDate(props.data?.selectDate)}
                </div>
              </div>
              <div className="card-appointment-detail">
                <Icon
                  circular
                  name="clock outline"
                  size="small"
                  style={{
                    background: "#E3F3F9",
                  }}
                />
                <div style={{ marginLeft: "10px" }}>{props.data?.time}</div>
              </div>
            </div>
            <div>
              {props?.hospital ? (
                <div
                  className="card-appointment-telemed"
                  style={{
                    background: "#96CF9E",
                    marginRight: "10px",
                  }}
                >
                  <Icon name="hospital outline" />
                  <div style={{ marginLeft: "2px" }}>{"โรงพยาบาล"}</div>
                </div>
              ) : props?.telemed ? (
                <div
                  className="card-appointment-telemed"
                  style={{ background: "#F493AC" }}
                >
                  <Icon name="video camera" />
                  <div style={{ marginLeft: "2px" }}>{"Telemedicine"}</div>
                </div>
              ) : (
                " "
              )}
            </div>
          </div>
        </div>
        {/* detail */}
        <div style={{ margin: "20px 5%" }}>
          <div
            className={"content-header-laban"}
            style={{ fontWeight: "bold" }}
          >
            อาการที่ต้องการรับการรักษา
          </div>
          <div className={"content-input-bottom"} style={{ marginTop: "10px" }}>
            <Input
              fluid={true}
              onChange={props.handleChangeValue("data.order_note")}
              value={props.data?.data?.order_note}
            />
          </div>
        </div>
        <div
          style={{
            margin: "50px 5px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Checkbox
              className="ui.toggle.checkbox"
              checked={props.addPerson}
              onChange={(e: any, { checked }: any) =>
                props.setAddPerson(checked)
              }
              style={{ margin: "0px 10px" }}
            />
            <div
              style={{
                color: COLOR.primary_font,
                fontWeight: "bold",
              }}
            >
              เพิ่มผู้ติดต่อกรณีฉุกเฉิน
            </div>
          </div>
          <div style={{ display: props.addPerson ? "block" : "none" }}>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div
                className={"content-input-bottom"}
                style={{ width: "50%", margin: "0px 10px" }}
              >
                <Input
                  fluid={true}
                  onChange={props.handleChangeValue("personName")}
                  value={props.data?.personName}
                  placeholder={intl.formatMessage({ id: "ชื่อ" })}
                />
              </div>
              <div
                className={"content-input-bottom"}
                style={{ width: "50%", margin: "0px 10px" }}
              >
                <Input
                  fluid={true}
                  onChange={props.handleChangeValue("personLastName")}
                  value={props.data?.personLastName}
                  placeholder={intl.formatMessage({ id: "นามสกุล" })}
                />
              </div>
            </div>

            <div
              className={"content-input-bottom"}
              style={{
                width: "",
                margin: "10px 10px",
                display: "flex",
              }}
            >
              <Input
                fluid={true}
                onChange={props.handleChangeValue("personPhone")}
                value={props.data?.personPhone}
                placeholder={intl.formatMessage({ id: "เบอร์โทรศัพท์" })}
                type="number"
                onKeyDown={(e: any) =>
                  /^[eE\-+.]$/.test(e.key) && e.preventDefault()
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* Button */}
      <div style={{ marginTop: "30px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "80%", fontWeight: "bold" }}>
            <div
              className="content-button "
              onClick={() => {
                props.handleCreateAppointmentIH();
              }}
            >
              {"ดำเนินการต่อ"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(
  injectIntl(IshealthPatientEditAppointment, { forwardRef: true })
);
