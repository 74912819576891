import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Divider,
  Form,
  Icon,
  Image,
  Input,
  SelectProps,
  Modal,
  Radio,
  Transition,
  InputProps,
  Button,
} from "semantic-ui-react";
import "react-lib/apps/IsHealth/IHMobile/ishealth.scss";
//type
import { dropDownOption, profileInfo } from "./TypeModal";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import CONFIG from "config/config";
import { useIntl } from "react-intl";
// import { logoSquare } from "/images/ishealth/logo-square.png";s

type MobEtcTypes = {
  onEvent?: any;
  history: any;
  setProps: any;
  systemType?: "IOS" | "ANDROID";
};

const colors = {
  BLUE: "#2362B2",
  BLUEIH: "#C1E3EF",
};

const styles = {
  etcCrad: {
    display: "flex",
    padding: "5px 16px",
    borderRadius: "15px",
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    marginBottom: "10px",
  } as CSSProperties,
  imageStyle: {
    display: "flex",
    height: "40px",
    width: "40px",
    border: "3px solid white",
    padding: "5px",
    backgroundColor: colors.BLUEIH,
    borderRadius: "10px",
    boxShadow: " 0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
    position: "relative",
  } as CSSProperties,
  textStyle: {
    fontSize: "18px",
    fontWeight: "bold",
    padding: "0 15px",
    color: colors.BLUE,
  } as CSSProperties,
};

const MobEtc = (props: MobEtcTypes) => {
  const intl = useIntl();

  const MENU_LIST = [
    {
      name: intl.formatMessage({id: "แสกน QR code (รับยา)"}),
      image: "icon_payment",
      route: "drugrecive",
    },
    {
      name: intl.formatMessage({id: "ประวัติการรับยาจากขนส่ง"}),
      image: "icon_medication",
      route: "shopping",
    },
    {
      name: intl.formatMessage({id: "ชำระเงิน"}),
      image: "icon_qrcode_scan",
      route: "payment",
    },
    {
      name: intl.formatMessage({id: "ตั้งค่าผู้ใช้งาน"}),
      image: "icon_setting",
      route: "setting",
    },
    {
      name: intl.formatMessage({id: "เปลี่ยนรหัสผ่าน"}),
      image: "icon_password",
      route: "chang_password",
    },
    {
      name: intl.formatMessage({id: "เชื่อมต่อ HealthKit"}),
      image: "icon_health_kit",
      route: "health-kit",
    },
  ];

  const gotoEtcMenu = (route: string) => {
    props.setProps("errorMessage", "");
    props.setProps("errorMessage", "");
    if (route === "health-kit") {
      if (globalThis.iosNative?.openHealthKit) {
        globalThis.iosNative.openHealthKit()
      } else if (globalThis.MobNative?.openHealthConnect) {
        globalThis.MobNative.openHealthConnect()
      }
    } else {
      props.history.push(route);
    }
  };

  const handleCheckOS = () => {
    let label = intl.formatMessage({ id: "เชื่อมต่อ Health Kit" })
    if (globalThis.MobNative?.openHealthConnect) {
      label = intl.formatMessage({ id: "เชื่อมต่อ Google Fit" })
    } 

    return label
  }

  return (
    <div className="content-header">
      <div className="content-detail">
        <div style={{ padding: "22.5px 20px" }}>
          <div>
            {MENU_LIST.map(
              (item, index) =>
                !CONFIG.HIDDEN_MENU.includes(item.route) && (
                  <div
                    style={styles.etcCrad}
                    key={index}
                    onClick={() => gotoEtcMenu(item.route)}
                  >
                    <div style={styles.imageStyle}>
                      <Image
                        src={
                          item.image
                            ? `/images/ishealth/menu_etc/${item.image}.png`
                            : "/images/ishealth/bottom_bar/appointment.png"
                        }
                        style={{ width: "25px" }}
                      />
                      {item.route === "chang_password" && (
                        <Image
                          src={`/images/ishealth/menu_etc/icon_lock.png`}
                          style={{
                            width: "15px",
                            position: "absolute",
                            bottom: "3px",
                            right: "3px",
                          }}
                        />
                      )}
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={styles.textStyle}>{item.route === "health-kit" ? handleCheckOS() : item.name}</div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MobEtc.displayname = "Etc";
export default MobEtc;
