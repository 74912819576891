import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Icon,
  Divider,
  Button,
  Modal,
  Segment,
  Input,
  Checkbox,
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import ModConfirm from "../common/ModConfirm";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useIntl } from "react-intl";
import { State } from "patient-lib/MobIsHealthInterface";
import PulltoRefresh from "react-lib/apps/common/PullToRefresh";
import Skeleton from "@mui/material/Skeleton";

interface RouterProps {}

type CardPatientAppointmentIshealthProps = {
  editAble?: boolean;
  handleGoBack?: any;
  getPatientAppointmentList?: any;
  dataList?: any;
  onEdit?: any;
  onCancel?: any;
  onConfirm?: any;
  setProps?: any;
  onEvent?: any;
  patientId?: any;
  viewIndex?: any;
} & Pick<State, "loadingStatus" >;

const COLOR = {
  primary: "var(--primary-theme-color)",
};

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    padding: "2.5px",
    overflow: "auto",
    heigth: "100%",
    width: "100%",
  },
  cardDiv: {
    padding: "5px",
    margin: "8px 0px",
    width: "100%",
    border: "2px solid #D3D3D3",
    borderRadius: "5px",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.25)",
  },
  columnDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  columnDivAlignLeftPadding: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    padding: "0px 5px",
  },
  columnDivAlignLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
  },
  rowDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    padding: "5px 0px",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  doctorHeader: {
    fontSize: "18px",
    color: "var(--primary-theme-color)",
    fontWeight: "bold",
    padding: "5px 0px",
  },
  contentBlue: {
    fontSize: "16px",
    color: "var(--primary-theme-color)",
    padding: "5px 0px",
  },
  contentGrey: {
    fontSize: "16px",
    color: "#746A6A",
    padding: "5px 0px",
  },
  flexWidthAuto: {
    width: "auto",
    display: "flex",
    justifyContent: "center",
    paddingRight: "12px",
    fontSize: "16px",
  },
  fontContentGrey: {
    fontSize: "14px",
    color: "#31666E",
    margin: "5px 0",
  },
  fontContentBlack: {
    fontSize: "14px",
  },
  mobCancel: {
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.primary + "!important",
    borderColor: COLOR.primary + "!important",
  },
  pull_to_refresh: {
    "& .ptr__pull-down": {
      top: "1rem",
    },
    "& .ptr__children": {
      paddingBottom: "20px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
  },
}));

const ACTION = {
  getListAppointment: "GET_LIST_APPOINTMENT",
};

const CardPatientAppointmentIshealth = (
  props: CardPatientAppointmentIshealthProps
) => {
  const intl = useIntl();
  const classes = useStyles();
  const toDate = moment().format("YYYY-MM-DD");
  const [oldAppointment, setOldAppointment] = useState<boolean>(false);
  const [expandedItem, setExpandedItem] = useState<any>([]);
  const [modConfirm, setModConfirm] = useState<any>({
    open: false,
    confirm: false,
    selectedItem: {},
  });
  const [modCancel, setModCancel] = useState<any>({
    open: false,
    confirm: false,
    selectedItem: {},
    note: "",
  });

  const handleClickItemExpand = (itemId: any) => {
    let expandTemp = [...expandedItem];
    if (expandTemp.includes(itemId)) {
      expandTemp = expandTemp.filter((item: any) => item != itemId);
    } else {
      expandTemp.push(itemId);
    }
    setExpandedItem(expandTemp);
  };

  const handleClickCancel = (item: any) => {
    setModCancel({
      open: true,
      confirm: false,
      selectedItem: { ...item },
    });
  };

  const handleClickConfirm = (item: any) => {
    let year = parseInt(item?.estimated_at_iso.format("YYYY")) + 543;
    let date = `${moment(item?.estimated_at_iso).format("DD MMMM")} ${year}`;

    setModConfirm({
      open: true,
      confirm: false,
      selectedItem: { ...item, date },
    });
  };

  const handleConfirm = () => {
    props.onCancel(modConfirm.selectedItem);
    props.onEvent({
      message: "HandleConfirmAppointment",
      params: {
        id: modConfirm.selectedItem?.id,
        selectedItem: modConfirm.selectedItem,
      },
    });
    handleCloseModal();
  };

  const handleConfirmCancel = () => {
    if (modCancel.note) {
      props.onCancel(modCancel.selectedItem);
      props.onEvent({
        message: "HandleCancelAppointment",
        params: {
          id: modCancel.selectedItem?.id,
          patientId: props.patientId,
          viewIndex: props.viewIndex,
          note: modCancel.note,
        },
      });
      setExpandedItem([]);
      handleCloseModalCancel();
    }
  };

  const handleCloseModalCancel = () => {
    setModCancel({
      open: false,
      confirm: false,
      selectedItem: {},
    });
  };

  const handleCloseModal = () => {
    setModConfirm({
      open: false,
      confirm: false,
      selectedItem: {},
    });
  };

  const handleChecked = (event: any, data: any) => {
    setOldAppointment(data.checked);
  };

  return (
    <div className={classes.mainDiv}>
      {props.loadingStatus?.[ACTION.getListAppointment] ? (
        <> {new Array(3).fill("").map((item) => CardLoading())}</>
      ) : (
        <>
          {(props.dataList || [])?.map((item: any, index: number) => (
            <CardPatientAppointmentIshealthItem
              key={`patientAppointment-${item.id}`}
              showExpaned={props.editAble}
              patientAppointment={item}
              isExpanded={expandedItem.includes(item.id)}
              onClickExpand={() => {
                let estimated = moment(item.estimated_at_iso).format(
                  "YYYY-MM-DD"
                );

                if (
                  props.viewIndex === 1 &&
                  moment(toDate).isSameOrAfter(estimated)
                ) {
                  return;
                } else {
                  handleClickItemExpand(item.id);
                }
              }}
              onEdit={() => {
                props.onEdit(item);
              }}
              onCancel={() => {
                handleClickCancel(item);
              }}
              onConfirm={() => {
                handleClickConfirm(item);
              }}
              languageUX={props.languageUX}
            />
          ))}
        </>
      )}

      <Modal open={modConfirm.open} size="mini">
        <Segment padded align="center" className="modContent">
          <div>
            <div className={classes.mobCancel} style={{ marginTop: "20px" }}>
              {intl.formatMessage({
                id: "ท่านต้องการยืนยันนัดหมายใช่หรือไม่ ?",
              })}
            </div>
            <div style={{ whiteSpace: "pre-line", margin: "40px 0px 25px" }}>
              {`วันที่  ${modConfirm.selectedItem.date}  ${moment(
                modConfirm.selectedItem.estimated_at_iso
              ).format("HH:mm")} \n${modConfirm.selectedItem.division_name}`}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginTop: 20 }}>
              <Button
                style={{
                  background: "#8BB6C6",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={handleCloseModal}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.cancel" />
                </div>
              </Button>
              <Button
                style={{
                  background: "#1ABDD4",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={handleConfirm}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.confirm" />
                </div>
              </Button>
            </div>
          </div>
        </Segment>
      </Modal>

      <Modal open={modCancel.open} size="mini">
        <Segment padded align="center" className="modContent">
          <div>
            <div className={classes.mobCancel} style={{ marginTop: "20px" }}>
              {intl.formatMessage({
                id: "ท่านต้องการยกเลิกนัดหมายนี้หรือไม่",
              })}
            </div>
            <div style={{ margin: "20px 0px", width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginBottom: "10px",
                }}
              >
                เหตุผลยกเลิกนัดหมาย
                <span style={{ color: "red" }}>*</span>
              </div>
              <Input
                fluid={true}
                onChange={(_event: any, data: any) => {
                  setModCancel({ ...modCancel, note: data.value });
                }}
                value={modCancel.note}
                placeholder={intl.formatMessage({
                  id: "กรุณาระบุเหตุผลที่ยกเลิกนัดหมาย",
                })}
              />
            </div>
            <div style={{ margin: "20px 0px" }}>
              {"หากท่านยกเลิกแล้ว จะต้องทำนัดหมายใหม่"}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ marginTop: 15 }}>
              <Button
                style={{
                  background: "#8BB6C6",
                  color: "white",
                  borderRadius: "20px",
                }}
                onClick={handleCloseModalCancel}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.cancel" />
                </div>
              </Button>
              <Button
                style={{
                  background: "#1ABDD4",
                  color: "white",
                  borderRadius: "20px",
                }}
                disabled={!modCancel.note}
                onClick={() => handleConfirmCancel()}
              >
                <div style={{ margin: "0px 20px" }}>
                  <FormattedMessage id="common.confirm" />
                </div>
              </Button>
            </div>
          </div>
        </Segment>
      </Modal>
    </div>
  );
};

interface CardPatientAppointmentIshealthItemProps {
  // key?: any;
  patientAppointment?: any;
  showExpaned?: boolean;
  isExpanded?: boolean;
  onClickExpand?: any;
  onEdit?: any;
  onCancel?: any;
  onConfirm?: any;
}

const CardPatientAppointmentIshealthItem = (
  props: CardPatientAppointmentIshealthItemProps
) => {
  const intl = useIntl();
  const classes = useStyles();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    handleSetData(props.patientAppointment);
  }, [props.patientAppointment]);

  const handleSetData = (patientApp: any) => {
    let tempData = {
      id: patientApp?.id || null,
      doctor_full_name: "",
      division_name: "",
      date: "",
      time: "",
      is_telemed: patientApp?.is_telemed,
      note: patientApp?.order_note || "-",
      emergency_contact: patientApp?.emergency_contact,
      emergency_telephone: "",
    };

    if (patientApp?.doctor_full_name) {
      tempData["doctor_full_name"] = patientApp?.doctor_full_name;
    }

    if (patientApp?.display_info?.division_name) {
      tempData["division_name"] = patientApp?.display_info?.division_name;
    }

    let startDate = moment(patientApp?.estimated_at_iso);
    if (startDate) {
      let year = parseInt(startDate.format("YYYY")) + 543;

      tempData["date"] = `${startDate.format("DD/MM/")}${year}`;

      tempData["time"] = startDate.format("HH:mm");
    }

    if (patientApp?.emergency_contact_telephone_number) {
      let phone_no = patientApp?.emergency_contact_telephone_number;
      tempData["emergency_telephone"] = `${phone_no.slice(
        0,
        3
      )}-${phone_no.slice(3, 10)}`;
    }
    setData(tempData);
  };

  return (
    <>
      <div className="card-appointment" onClick={props.onClickExpand}>
        <Accordion expanded={props.isExpanded} onChange={props.onClickExpand}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <div className="accordion-summary">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className={classes.contentBlue}>
                  {data?.doctor_full_name}
                </div>
                {props.patientAppointment?.status === 2 && (
                  <div
                    className="card-appointment-telemed"
                    style={{
                      background: "#1AB3CB",
                      fontSize: "9px",
                      height: "20px",
                    }}
                  >
                    <div>{"ยืนยันนัดหมาย"}</div>
                  </div>
                )}
              </div>
              <div className={classes.fontContentGrey}>
                {" "}
                {data?.division_name}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="card-appointment-detail">
                    <Icon
                      circular
                      name="calendar alternate outline"
                      size="small"
                      style={{
                        background: "#E3F3F9",
                      }}
                    />
                    <div>{data?.date}</div>
                  </div>
                  <div className="card-appointment-detail">
                    <Icon
                      circular
                      name="clock outline"
                      size="small"
                      style={{
                        background: "#E3F3F9",
                      }}
                    />
                    <div>{data?.time}</div>
                  </div>
                </div>
                {!props.patientAppointment?.is_locked_appointment && (
                  <div>
                    {data?.is_telemed ? (
                      <div
                        className="card-appointment-telemed"
                        style={{ background: "#F493AC" }}
                      >
                        <Icon name="video camera" />
                        <div>{"Telemedicine"}</div>
                      </div>
                    ) : (
                      <div
                        className="card-appointment-telemed"
                        style={{ background: "#96CF9E" }}
                      >
                        <Icon name="hospital outline" />
                        <div>{"OPD"}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-details">
              {props.patientAppointment?.is_locked_appointment ? (
                <div style={{ borderTop: "1px solid #D9D9D9" }}>
                  <div
                    style={{
                      margin: "20px 0px",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      color: "#F69F98",
                    }}
                  >
                    {
                      "ต้องการเปลี่ยนแปลง ยกเลิกนัดหมาย สอบถามเพิ่มเติม กรุณาติดต่อ เจ้าหน้าที่ของโรงพยาบาล"
                    }
                  </div>
                </div>
              ) : (
                <>
                  <div style={{ borderTop: "1px solid #D9D9D9" }}>
                    <div
                      style={{ marginTop: "10px" }}
                      className={classes.fontContentGrey}
                    >
                      {" "}
                      รายละเอียดเพิ่มเติม
                    </div>
                    <div className={classes.fontContentBlack}>{data?.note}</div>
                    <div className={classes.fontContentGrey}>
                      ผู้ติดต่อกรณีฉุกเฉิน
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        margin: "10px 0",
                      }}
                    >
                      {(data?.emergency_contact &&
                        !(data?.emergency_contact || "")
                          .toLowerCase()
                          .includes("none")) ||
                      data?.emergency_telephone ? (
                        <>
                          <div
                            className={classes.fontContentBlack}
                            style={{ width: "100%" }}
                          >
                            {data?.emergency_contact || "ไม่ระบุข้อมูล"}
                          </div>
                          <div
                            className={classes.fontContentBlack}
                            style={{ width: "100%" }}
                          >
                            {data?.emergency_telephone || ""}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={classes.fontContentBlack}
                            style={{ width: "100%" }}
                          >
                            {"ไม่ระบุข้อมูล"}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {props.patientAppointment?.status === 2 ? (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div
                        className="content-common-button"
                        onClick={() => {}}
                        style={{ background: "#31666E" }}
                      >
                        <div style={{ margin: "0px 20px" }}>
                          <Icon name="phone" flipped="horizontally"></Icon>{" "}
                          {`ติดต่อเจ้าหน้าที่`}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="content-common-button"
                        onClick={props.onConfirm}
                        style={{
                          background: "#96CF9E",
                          minWidth: "90px",
                          textAlign: "center",
                          marginLeft: 0,
                        }}
                      >
                        <div style={{}}>
                          <FormattedMessage id="common.approve" />
                        </div>
                      </div>
                      <div
                        className="content-common-button"
                        onClick={props.onEdit}
                        style={{
                          background: "#25A7B9",
                          minWidth: "90px",
                          textAlign: "center",
                        }}
                      >
                        <div style={{}}>
                          <FormattedMessage id="appoint.postpone" />
                        </div>
                      </div>
                      <div
                        className="content-common-button"
                        onClick={props.onCancel}
                        style={{
                          background: "#F69E97",
                          minWidth: "90px",
                          textAlign: "center",
                        }}
                      >
                        <div style={{}}>
                          <FormattedMessage id="appoint.cancel" />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "10px 0px 0px",
                  alignItems: "center",
                }}
              >
                {props.patientAppointment?.is_locked_appointment && (
                  <div
                    className="content-common-button"
                    onClick={() => {}}
                    style={{ background: "#31666E" }}
                  >
                    <div style={{ margin: "0px 20px" }}>
                      <Icon name="phone" flipped="horizontally"></Icon>{" "}
                      {`ติดต่อเจ้าหน้าที่`}
                    </div>
                  </div>
                )}
                <Icon name="chevron up" color="grey" size="large" />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

const CardLoading = () => {
  return (
    <div className="card-appointment">
      <Accordion expanded={false}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className="accordion-summary">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Skeleton animation="pulse" width={"40%"} />
              <Skeleton animation="pulse" width={"20%"} />
            </div>
            <Skeleton animation="pulse" width={"50%"} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <div className="card-appointment-detail">
                  <Icon
                    circular
                    name="calendar alternate outline"
                    size="small"
                    style={{
                      background: "#E3F3F9",
                      marginLeft: "10px",
                    }}
                  />
                </div>
                <Skeleton animation="pulse" width={"20%"} />
                <div className="card-appointment-detail">
                  <Icon
                    circular
                    name="clock outline"
                    size="small"
                    style={{
                      background: "#E3F3F9",
                      marginLeft: "10px",
                    }}
                  />
                </div>
                <Skeleton animation="pulse" width={"20%"} />
              </div>
              <Skeleton animation="pulse" width={"10%"} />
            </div>
          </div>
        </AccordionSummary>
      </Accordion>
    </div>
  );
};

CardPatientAppointmentIshealth.defaultProps = {
  editAble: false,
  patientAppointmentList: [],
  noApiToken: () => {},
  match: null,
  onEdit: () => {},
  onCancel: () => {},
  onConfirm: () => {},
};

export default CardPatientAppointmentIshealth;
