import React, { useEffect, useMemo, useState } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import Cookies from "js-cookie";
import { PatientData } from "react-lib/apps/QUE/Model";
import { makeStyles } from "@mui/styles";
import PulltoRefresh from "react-lib/apps/common/PullToRefresh";

import {
  Dimmer,
  Loader,
  Menu,
  Tab,
  TabProps,
  Button,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import {
  serial_to_datestr,
  PatientAppointment,
  QueueController,
} from "react-lib/apps/QUE/Time";
import CardPatientAppointmentIshealth from "./CardPatientAppointmentIshealth";
import { FormattedMessage } from "react-intl";
import { State } from "patient-lib/MobIsHealthInterface";
import { type } from "os";

const COLOR = {
  primary: "var(--primary-theme-color)",
};

const ACTION = {
  getListAppointment: "GET_LIST_APPOINTMENT",
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  tabActive: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    color: COLOR.primary + "!important",
    borderColor: COLOR.primary + "!important",
  },
  absoluteBottomContent: {
    position: "fixed",
    bottom: "10%",
    width: "100%",
    textAlign: "center",
  },
  absoluteNoneAppointment: {
    position: "absolute",
    bottom: "55%",
    fontSize: "22px",
    color: "#746A6A",
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
  },
  pull_to_refresh: {
    "& .ptr__pull-down": {
      top: "1rem",
    },
    "& .ptr__children": {
      paddingBottom: "20px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      minHeight: "65vh"
    },
  },
  checkBox: {
    "& .ui.checkbox input:checked~label:after ":{
      color: "white"
    },
    "& .ui.checkbox input:checked~label:before":{
      background: COLOR.primary
    },
  }
}));

type IshealthAppointmentPatientProps = {
  controller?: QueueController;
  handleGoBack?: any;
  noApiToken?: any;
  header?: any;
  displayDatetimeIso?: boolean;
  onMakeAppointment?: any;
  setProps?: any;
  onEvent?: any;
} & Pick<
  State,
  | "loadingStatus"
  | "patientAppointmentList"
  | "apiToken"
  | "appointmentData"
  | "patientData"
>;

const IshealthAppointmentPatient = (props: IshealthAppointmentPatientProps) => {
  const history = useHistory();
  const classes = useStyles();
  const isMounted = React.useRef(true);
  const swipeableRef = React.useRef<any>();
  const [oldAppointment, setOldAppointment] = useState<boolean>(false);
  const [patientAppointmentList, setPatientAppointmentList] = useState<any[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewIndex, setViewIndex] = useState<any>(0);

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.patientData?.id) {
      handleGetPatientAppointmentList();
    }
  }, [props.patientData?.id, viewIndex]);

  useEffect(() => {
    if (isMounted.current) {
      if (props.patientAppointmentList) {
        let newArr: PatientAppointment[] = props.patientAppointmentList?.items
          ?.sort(
            (
              a: { estimated_at_iso: string },
              b: { estimated_at_iso: string }
            ) => {
              if (!a.estimated_at_iso && !b.estimated_at_iso) return 0;
              else if (!a.estimated_at_iso) return -1;
              else if (!b.estimated_at_iso) return 1;
              else
                return (
                  moment(a.estimated_at_iso).valueOf() -
                  moment(b.estimated_at_iso).valueOf()
                );
            }
          )
          .filter((item: any) => item.status !== 4);

        setPatientAppointmentList(newArr);
      } else {
        setPatientAppointmentList([]);
      }
    }
  }, [props.patientAppointmentList]);


  const dataList = useMemo(() => {
    const today = moment();
    if (!props.patientAppointmentList) {
      return []
    } else {
      let newArr: PatientAppointment[] = props.patientAppointmentList?.items
      ?.sort(
        (
          a: { estimated_at_iso: string },
          b: { estimated_at_iso: string }
        ) => {
          if (!a.estimated_at_iso && !b.estimated_at_iso) return 0;
          else if (!a.estimated_at_iso) return -1;
          else if (!b.estimated_at_iso) return 1;
          else
            return (
              moment(a.estimated_at_iso).valueOf() -
              moment(b.estimated_at_iso).valueOf()
            );
        }
      )
      .filter((item: any) => item.status !== 4);
  
      let lastResult = newArr;
      if (viewIndex === 1) {
        lastResult = newArr;
      } else {
        if (oldAppointment) {
          lastResult = (newArr || []).filter((item: any) =>
            moment(item.estimated_at_iso).isBefore(today)
          );
        } else {
          lastResult = (newArr || []).filter((item: any) =>
            moment(item.estimated_at_iso).isAfter(today)
          );
        }
      }
      console.log("Debug ~ lastResult:", lastResult)
      return (lastResult || [])
        ?.filter((item: any) => item?.status !== 5)
        ?.filter((item: any) => item?.display_info);
    }
  }, [props.patientAppointmentList, oldAppointment]);

  const panes = React.useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item
            key="currentAppointment"
            className={viewIndex === 0 ? classes.tabActive : classes.tabs}
          >
            <FormattedMessage id="appoint.appointment_list" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item
            key="historyAppointment"
            className={viewIndex === 1 ? classes.tabActive : classes.tabs}
          >
            <FormattedMessage id="appoint.appointment_history" />
          </Menu.Item>
        ),
      },
    ],
    [viewIndex]
  );

  const handleGetPatientAppointmentList = () => {
    props.onEvent({
      message: "HandlePatientAppointmentView",
      params: {
        patientId: props.patientData?.id,
        viewIndex: viewIndex,
        card: ACTION.getListAppointment,
      },
    });
  };

  const handleCancelPatientAppointment = async (item: any) => {
    console.log("handleCancelPatientAppointment item >>> ", item.id);
  };

  const handleEditPatientAppointment = (item: any) => {
    console.log("handleEditPatientAppointment item >>> ", item.id);

    history.push(`/editAppointmentIH/${item.id}`);

    props.onEvent({
      message: "HandleEditAppointment",
    });
  };

  const handleEditAppointmentIH = () => {
    history.push(`/appointmentIH`);
  };

  const handleChecked = (event: any, data: any) => {
    setOldAppointment(data.checked);
  };

  return (
    <div className={"content-detail"} style={{ overflow: "hidden" }}>
      <div style={{ padding: "17.5px" }}>
        {/* {props.header} */}
        {/* <Dimmer.Dimmable style={{ padding: "0px" }}>
          <Dimmer active={isLoading} inverted>
            <Loader inverted />
          </Dimmer> */}
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={viewIndex}
          onTabChange={(event: any, data: TabProps) => {
            setViewIndex(data.activeIndex);
          }}
        />
        <SwipeableViews
          ref={swipeableRef}
          animateTransitions={true}
          index={viewIndex}
          onChangeIndex={(index: number) => {

            setViewIndex(index);
          }}
          style={{
            marginTop: "20px",
            width: "",
            paddingBottom: "120px",
          }}
        >
          {/* current patient appointment */}
          <div>
            <div
             className={classes.checkBox}
              style={{
                display: viewIndex !== 1 ? "flex" : "none",
                justifyContent: "flex-end",
                width: "100%",
                paddingBottom: "10px",
              }}
            >
              <Checkbox
                style={{ margin: "0px 10px" }}
                checked={oldAppointment}
                onChange={handleChecked}
              />
              <div
                style={{
                  color: COLOR.primary,
                  fontWeight: "bold",
                }}
              >
                เฉพาะนัดหมายเก่า
              </div>
            </div>
            <PulltoRefresh
              className={classes.pull_to_refresh}
              onRefresh={handleGetPatientAppointmentList}
            >
              <CardPatientAppointmentIshealth
                editAble={true}
                dataList={dataList}
                handleGoBack={props.handleGoBack}
                onEdit={handleEditPatientAppointment}
                onCancel={handleCancelPatientAppointment}
                setProps={props.setProps}
                onEvent={props.onEvent}
                getPatientAppointmentList={handleGetPatientAppointmentList}
                patientId={props.patientData?.id}
                viewIndex={viewIndex}
                loadingStatus={props.loadingStatus}
                languageUX={props.languageUX}
              />
            </PulltoRefresh>
          </div>

          {/* patient appointment history */}
          <PulltoRefresh
            className={classes.pull_to_refresh}
            onRefresh={handleGetPatientAppointmentList}
          >
            <CardPatientAppointmentIshealth
              editAble={false}
              dataList={dataList}
              handleGoBack={props.handleGoBack}
              onEdit={handleEditPatientAppointment}
              onCancel={handleCancelPatientAppointment}
              setProps={props.setProps}
              onEvent={props.onEvent}
              getPatientAppointmentList={handleGetPatientAppointmentList}
              patientId={props.patientData?.id}
              viewIndex={viewIndex}
              loadingStatus={props.loadingStatus}
              languageUX={props.languageUX}
            />
          </PulltoRefresh>
        </SwipeableViews>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            left: "50%",
            transform: "translate(-50%, 0)",
            bottom: "75px",
          }}
        >
          <div
            className="content-button"
            // inverted
            onClick={() => {
              //   props.onMakeAppointment?.();
              props.setProps(`appointmentData.detail`, "");
              handleEditAppointmentIH();
            }}
          >
            <Icon name="plus" style={{ color: "whith" }} />
            สร้างรายการนัดหมายใหม่
          </div>
        </div>

        {!props.loadingStatus?.[ACTION.getListAppointment] &&
          dataList?.length === 0 && (
            <div className={classes.absoluteNoneAppointment}>
              <FormattedMessage id="appoint.no_appointment" />
            </div>
          )}
        {/* </Dimmer.Dimmable> */}
      </div>
    </div>
  );
};

IshealthAppointmentPatient.defaultProps = {
  patientData: {},
  controller: {},
  noApiToken: () => {},
  onMakeAppointment: () => {},
  header: {},
  displayDatetimeIso: false,
  match: null,
};

export default IshealthAppointmentPatient;
